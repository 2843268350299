import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import emailjs from "@emailjs/browser";
import { useFormik } from "formik";
import * as Yup from "yup";
const validationSchema = Yup.object().shape({
  name: Yup.string().required("First Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  phone: Yup.string().required("Phone is required"),
  message: Yup.string().required("Message is required"),
});

const ArtWorkDetails = () => {
  const [artDetail, setArtDetail] = useState({});
  const form = useRef();
  const [submit, setSubmit] = useState(false);
  const { slug } = useParams();

  useEffect(() => {
    const id = slug;
    axios
      .get(`https://admin.jccaindia.com/api/get_artworks/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setArtDetail(res.data.data);
        }
      })
      .catch((err) => console.log(err.message));
    return () => {};
  }, []);
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const Name=values.name;
          const ProductName=artDetail.name;
          const Email=values.email;
          const Phone=values.phone;
          const Message=values.message;
          sendMessage(Name,ProductName,Email,Phone,Message);
      emailjs
        .sendForm(
            "service_cgnl9jl",
            "template_9xh5mem",
            form.current,
            "9_YyA6cpMfj2wTknb"
        )
        .then(
          (result) => {
            console.log(result.text);
            window.location.reload();
          },
          (error) => {
            console.log(error.text);
          }
        );
      setSubmit(true);
    },
  });
  const sendMessage = async (Name,ProductName,Email,Phone,Message) => {
    const accessToken = 'EAADGAaq3TKIBOyZArnhTyitiSwQq5tn7WBFERRSbY6kD17GkfiPTblNH8CuZC0RfjrgdW8yzG1jvOV7bHB5pZAXshSnziYOvocLK4x8AUNyWs2ueh4MN2w5lNZBEqDycRLZCBS2OPZAdoxktAxm9rrLtygqBBQtP6bJteobvY3SoeGbtfNDPqOQgPU8KUvzjEZC'; 

    const apiUrl = 'https://graph.facebook.com/v18.0/217976964727784/messages';
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };
    
    const payload = {
            messaging_product: "whatsapp",
            recipient_type: "individual",
            to: "916350683793",
            type: "text",
            text: { 
              preview_url: true,
              body: `Name: ${Name}, Phone No.: ${Phone}, Email: ${Email}, ArtWork: ${ProductName}, Message ${Message}` 
            }
          
    };

    try {
      const response = await axios.post(apiUrl, payload, { headers });

      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error.response ? error.response.data : error.message);
    }
  }; 
  return (
    <>
      <Header />
      <div style={{ marginTop: "100px" }}>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title" id="exampleModalLabel">
                  {artDetail.name}
                </h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <div class="contact-form-area contact_araes">
                  <div class="contact-form-area-inner ">
                    <form
                      ref={form}
                      onSubmit={formik.handleSubmit}
                      className="contact-form"
                      id="contact-forms"
                    >
                        <input
                            style={{ width: "100%", display: "none" }}
                            name="product_name"
                            type="text"
                            value={artDetail.name}                         
                            className="border rounded py-2"
                            />
                      <div className="col-12">
                        <div className="form-group">
                          <input
                            style={{ width: "100%" }}
                            name="name"
                            type="text"
                            value={formik.values.name}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder="First Name"
                            className="border rounded py-2"
                          />
                          {formik.errors.name &&
                            formik.touched.name && (
                              <p style={{ color: "red" }}>
                                {formik.errors.name}
                              </p>
                            )}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <input
                            style={{ width: "100%" }}
                            name="email"
                            value={formik.values.email}
                            className="border rounded py-2"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="email"
                            placeholder="Email Address"
                          />
                          {formik.errors.email && formik.touched.email && (
                            <p style={{ color: "red" }}>
                              {formik.errors.email}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <input
                            style={{ width: "100%" }}
                            name="phone"
                            value={formik.values.phone}
                            className="border rounded py-2"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            type="text"
                            placeholder="Phone"
                          />
                          {formik.errors.phone && formik.touched.phone && (
                            <p style={{ color: "red" }}>
                              {formik.errors.phone}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            value={formik.values.message}
                            className="border rounded"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            placeholder="Message"
                          ></textarea>
                          {formik.errors.message && formik.touched.message && (
                            <p style={{ color: "red" }}>
                              {formik.errors.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-12 text-center">
                        <div className="loading"></div>
                        <div class="modal-book">
                          <button type="submit" class="btn btn-primary inquery">Enquiry</button>
                          <button type="button" class="btn btn-secondary inquery-close" data-bs-dismiss="modal">Close</button>                          
                        </div>
                      </div>
                    </form>

                    <div class={submit ? "show mrt-15" : "done mrt-15"}>
                      <strong>Thank you!</strong> We have received your message.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container bookdetail-container py-4">
        <div className="col-md-6 col-sm-12 text-center">
          <img src={"https://admin.jccaindia.com" + artDetail.image} style={{width:'100%'}} alt="hi" />
        </div>
        <div className="col-md-6 col-sm-12 ">
          <h1 className="art-name">{artDetail.name}</h1>
          <p className="art-detail">{artDetail.title}</p>
          <p className="art-detail">{artDetail.description}</p>
          <h4 className="art-detail">{artDetail.size}</h4>
          {/* <h6 className="book-price">
            {" "}
            <span>&#8377;</span>
            {artDetail.price}
          </h6> */}
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            className=" btn-req"
          >
            Price on Request
          </button>{" "}
          <br />
          <br />
          {/* {artDetail?.amazon && (
            <a
              href={artDetail?.amazon}
              style={{
                cursor: "pointer",
                fontSize: "20px",
                textDecoration: "underline",
              }}
              className="mt-4  text-black"
            >
              Available on Amazon
            </a>
          )} */}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ArtWorkDetails;
