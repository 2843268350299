import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
const CreativitiesPage = () => {
    const [portfolio, setPortfolio] = useState([]);
    useEffect(() => {
      axios
        .get("https://admin.jccaindia.com/api/get_portfolio/")
        .then((res) => {
          setPortfolio(res.data.data);
        })
        .catch((err) => console.log(err.message));
  
      return () => {};
    }, []);
  
    return (
        <>
      <Header/>
      <h1 className='text-center book-heading' style={{marginTop:'110px'}}>Creatives</h1>
        <div className=" book-container" style={{width:'100%',paddingBottom:'30px'}}>
            
       { portfolio?.map((x,i) => (
            <div key={i} className="m-1">
              <div className="card port-width">
                {/* <Link to={`/artworks`}> */}
                  <div className="book-img">
                    <img
                      
                      src={`${
                        "https://admin.jccaindia.com" + x.image
                      }?w=161&fit=crop&auto=format`}
                      alt={x.title}
                      loading="lazy"
                     className="port-img"
                    />
                   
                  </div>
                  <div className="port-body">
                    <h5 className="text-center p-2" style={{fontSize:'22px' ,fontWeight:'600'}}> {x.name}</h5>
                    
                  </div>
                {/* </Link> */}
              </div>
            </div> 
          ))}
        </div>

        <Footer/>
        </>
  )
}

export default CreativitiesPage