import * as Yup from 'yup'

export const contactschema=Yup.object({

    firstname:Yup.string().min(3).max(20).required('Please fill the first name'),
    lastname:Yup.string().min(3).max(20).required('Please fill the last name'),
    email:Yup.string().min(10).max(20).required('Please fill the  email'),
    phone:Yup.string().min(10).max(10).required('Please fill the phone'),
    message:Yup.string().min(5).max(50).required('Please fill the Message'),

})