import React, { useState } from 'react';
import Modal from 'react-modal';

const FullscreenImageModal = ({ imageUrl, onClose }) => {
  return (
    <Modal
      isOpen={!!imageUrl}
      onRequestClose={onClose}
      contentLabel="Fullscreen Image Modal"
    >
      
      <img src={imageUrl} alt="Fullscreen" style={{ width: '33%', height: '80%' , marginTop: "100px" }} />
      <div className="button-close"><button onClick={onClose}>Close</button>  </div>
    </Modal>
  );
};

export default FullscreenImageModal;
