import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import FullscreenImageModal from "../Component/FullScreenModal";


const Gallery = () => {
    const [portfolio, setPortfolio] = useState([]);
    const [fullscreenImageUrl, setFullscreenImageUrl] = useState(null);

    const handleImageClick = (imageUrl) => {
      setFullscreenImageUrl(imageUrl);
      
    };
  
    const handleCloseModal = () => {
      setFullscreenImageUrl(null);
    };
  
    useEffect(() => {
      axios
        .get("https://admin.jccaindia.com/api/allgallery/")
        .then((res) => {
          setPortfolio(res.data.data);
        })
        .catch((err) => console.log(err.message));
  
      return () => {};
    }, []);
   
  return (
    <>
    <Header/>
    <div style={{marginTop:'120px'}} className="mb-4">
        <h1 className="text-center">The JCCA Gallery</h1>
      <div className=" book-container">
     { portfolio.map((x,i) => (
          <div key={i} className="m-2 py-3">
            <div className="card g-width">
              {/* <Link to={`/artworks`}> */}
                <div className="gellary-img">
                  <img
                    
                    src={`${
                      "https://admin.jccaindia.com" + x.image
                    }?w=161&fit=crop&auto=format`}
                    alt={x.title}
                    loading="lazy"
                    style={{height:'360px',width:'100%'}}
                    onClick={() => handleImageClick(`${"https://admin.jccaindia.com" + x.image}`)}
                  />
                 
                </div>
                <FullscreenImageModal imageUrl={fullscreenImageUrl} onClose={handleCloseModal} />
              {/* </Link> */}
            </div>
          </div>
        ))}
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default Gallery