import React, { useEffect, useState } from 'react'

import axios from 'axios'
import { Link } from 'react-router-dom';
import Footer from '../Component/Footer';
import Header from '../Component/Header';
const NewsAndEvents = () => {
    const [news, setNews] = useState([]);

    useEffect(() => {

        axios.get('https://admin.jccaindia.com/api/news')
        .then((res)=> {
            if(res.status===200){
                setNews(res.data.data)
            }
        }).catch((err)=>console.log(err.message))

    }, [])
  return (
    <>
    <Header/>
    <div style={{marginTop:'130px'}}>
        <h1 className='text-center mb-3'>News & Events</h1>
    <section class="news-block">
    <div class="blog-page-block">
        <div class="container">
            
            <div class="row justify-content-center">
                <div class="col-12">

                <div class="blog-latest-items row">

                {
                 news?.map((x)=>                      
        
                 <div class="news-div-width">
                     <article class="post ">
                     <Link to={`/news-details/${x.slug}`}>
                         <figure class="post-thumb">
                           
                                 <img src={'https://admin.jccaindia.com'+x.image} alt="news" style={{height:'300px',width:'100%'}}/>
                        
                         </figure>
                         </Link>
                         <div class="post-details ice-details">
                             <div class="entry-meta-content">
                             {  x?.date &&   <div class="entry-cat text-black">
                                     
                                       Date: {x?.date?.slice(0,10).split('-').reverse().join('-')}
                                 </div> }
                                                         
                             </div>
                            <h2 class="entry-title"><Link to={`/news-details/${x.slug}`}>
                            {x.news_heading?.slice(0,40)}...</Link>
                            </h2>
                             <div class="entry-content" style={{textTransform:'capitalize'}}>
                                 <p>{x.news_detail?.slice(0,45)}...</p>
                                 <div class="btn-group-default date_btn">
                  
                     <Link  to={`/news-details/${x.slug}`} style={{color:'black',textDecoration:'underline',fontSize:'20px',textTransform:'capitalize'}}>
                     Read More
                
                      
                      </Link>
                 </div>
                             </div>
                            
                         </div>
                     </article>
                 </div>
                             
                 )
                }

                </div>
          
                </div>
            </div>
        </div>
    </div>
   
   </section>
 
 </div>
 <Footer/>
 </>
  )
}

export default NewsAndEvents