
import './App.css';

import HomePage from './Pages/HomePage';

import {BrowserRouter as Router , Switch, Route} from 'react-router-dom'
import MyCalender from './Component/MyCalender';
import ExhibitionDetail from './Component/ExhibitionDetail';
import About from './Component/About';
import TestimonialPage from './Pages/TestimonialPage';
import Artists from './Component/Artists';
import Contact from './Component/Contact';
import BookPage from './Pages/BookPage';
import ScrollToTop from './Component/ScrollToTop';
import BookDetails from './Pages/BookDetails';
import ArtistDetails from './Pages/ArtistDetails';
import ArtsWorksPage from './Pages/ArtsWorksPage';
import ArtWorkDetails from './Component/ArtWorkDetails';
import NewsAndEvents from './Pages/NewsAndEvents';
import NewsDetail from './Pages/NewsDetail';
import Gallery from './Pages/Gallery';
import CreativitiesPage from './Pages/CreativitiesPage';
import Creativiedetails from './Pages/Creativiedetails';
import Advisors from './Component/Advisors';
import AdvisorsDetails from './Pages/AdvisorsDetails';

function App() {
  return (

    <Router>

    <ScrollToTop/>
  



    <Switch>
    <Route path='/' exact component={HomePage} />

    <Route path='/exhibitions'  component={MyCalender} />

    <Route path='/exhibition/:slug'  component={ExhibitionDetail} />

    <Route path='/testimonial'  component={TestimonialPage} />

    <Route path='/creativies/:slug'  component={Creativiedetails} />

    <Route path='/about'  component={About} />
    <Route path='/jcca-advisors'  component={Advisors} />
    <Route path='/advisors-details/:slug'  component={AdvisorsDetails} />
    <Route path='/contact'  component={Contact} />

    <Route path='/artists'  component={Artists} />

    <Route path='/artworks'  component={ArtsWorksPage} />

    <Route path='/get_artworks/:slug'  component={ArtWorkDetails} />

    <Route path='/detailsartist/:slug'  component={ArtistDetails} />

    <Route path='/books'  component={BookPage} />

    <Route path='/detailbooks/:slug'  component={BookDetails} />

    <Route path='/news'  component={NewsAndEvents} />
    <Route path='/news-details/:slug'  component={NewsDetail} />
    <Route path='/gallery'  component={Gallery} />
    <Route path='/creativites'  component={CreativitiesPage} />
    
    
    </Switch>






    </Router>
  );
}

export default App;
