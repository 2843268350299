import React, {useEffect, useState } from 'react'
import { useParams } from 'react-router'
import axios from 'axios'
import Header from '../Component/Header';
import Footer from '../Component/Footer';

const Creativiedetails = () => {
    const [bookDetail, setBookDetail] = useState({})
   
    const {slug} =useParams();
    
    useEffect(() => {
      
        axios.get(`https://admin.jccaindia.com/api/get_single_creativies/${slug}`)
        .then((res)=>{
           if(res.status===200){
               setBookDetail(res.data.data)
           }
        })
        .catch((err)=>console.log(err.message))
        return () => {
       
        }
    }, [])
    
    
     
  return (
    <>
        <Header/>
     
       <div className='container bookdetail-container py-4' style={{marginTop:'100px'}}>
        <div className="col-md-6 col-sm-12 text-center">
            <img src={ 'https://admin.jccaindia.com'+ bookDetail.image} alt="hi" className='img-height'/>
        </div>
        <div className="col-md-6 col-sm-12">
            <h1 className='book-name'>{bookDetail.name}</h1>
            {/* <h2 className='book-writer'>By {bookDetail.writer}</h2> */}
            <p className='book-desc'>
                {bookDetail?.details}
            </p>
            {/* <h6 className='book-price'> <span>&#8377;</span>{bookDetail.price}</h6> */}
            {/* <button type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"  className=' btn-req'>Purchase
            </button> <br/>
            <br/>
           {bookDetail?.amazon && <a href={bookDetail?.amazon} style={{cursor:'pointer',fontSize:'20px',textDecoration:'underline'}}  className='mt-4  text-black'>Available on Amazon 
            </a>} */}
        </div>
        </div>
        <Footer/>
       
    </>
  )
}

export default Creativiedetails