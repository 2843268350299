import axios from 'axios';
import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Footer from '../Component/Footer';
import Header from '../Component/Header';

const AdvisorsDetails = () => {
    const [artist, setArtistDetail] = useState({})
    const {slug} =useParams();
    const [reading, setReading] = useState(false);
   
    useEffect(() => {        
        axios.get(`https://admin.jccaindia.com/api/get_advisor/${slug}`)
        .then((res)=>{
           if(res.status===200){
               setArtistDetail(res.data.data)
           }
        })
        .catch((err)=>console.log(err.message))
        return () => {
       
        }
        
    }, [])
    
  const contentShow=()=>{
    setReading(!reading)
  }
  return (
    <>

 <Header/>
        <div className='container bookdetail-container py-4' style={{marginTop:'120px'}}>
            
        <div className="col-md-6 col-sm-12 text-center" >
            <img src={ 'https://admin.jccaindia.com'+artist.image}  alt="hi" className=''/>
        </div>
        <div className="col-md-6 col-sm-12">
            <h1 className='book-name'>{artist.name}</h1>
            
            <p className='book-desc'>
                {!reading && <p style={{fontSize:'16px'}}>{artist.detail?.slice(0,artist.detail?.length/2)}...</p>}
                {reading && <p style={{fontSize:'16px'}}>{artist.detail}</p>}
                {!reading && <button className='border-0' onClick={contentShow} >Read More &rarr;</button>}
                { reading && <button className='border-0' onClick={contentShow} >Read Less &rarr;</button>}
           </p>
            
        </div>
        </div>

       
        <Footer/>
    </>
  )
}

export default AdvisorsDetails;