import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

function Advisors() {
  const [loading, setLoading] = useState(false);
  const [artist, setArtist] = useState([]);
  useEffect(() => {
    setLoading(true);
    axios.get("https://admin.jccaindia.com/api/get_advisors/")
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setArtist(res.data.data);
          
        }
      })
      .catch((err) => console.log(err.message));

    return () => {};
  }, []);

 

  

  return (
    <div>
      <Header />

      <h1 className='text-center book-heading' style={{marginTop:'110px'}}>
      JCCA Advisors
      </h1>
    {loading && <h1 className="p-4 text-center"><img src="../assets/images/loading.gif" alt="loading" /></h1>  }
    {!loading &&
    <div className=' book-container'>
      {artist.length<=0? <h1 className="p-4 ">Does Not Finds!</h1>:<>    
   
    {
        artist?.map((x)=>       
         ( 
         <div className="m-4"> 
         <div className="card exh-width" >
             <Link to={`/advisors-details/${x?.slug}`} >
             <div className=''>
             <img src={'https://admin.jccaindia.com'+x.image} alt="success-growth" className="" style={{width:'100%',objectFit:'contain'}}/>
             </div>
             <div className="exh-body">                 
                 <h5 className="exh-title text-center"> {x.name}</h5>
                                           
             </div>
             {/* <Link  to={`/exhibition/${x.slug}`} class="btn ">View Details</Link> */}
             </Link>
         </div>
        
         
     </div>

          ) )
    }
</>
}
    
    </div>
}

      <Footer />
    </div>
  );
}

export default Advisors;
