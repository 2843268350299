
import React, { useEffect, useState } from 'react'

import axios from 'axios'
import { Link } from 'react-router-dom';


function Events() {

    

    const [events, setEvents] = useState([]);

    useEffect(() => {

        axios.get('https://admin.jccaindia.com/api/events/')
        .then((res)=> {
            if(res.status===200){
                setEvents(res.data.data)
   

            }
        }).catch((err)=>console.log(err.message))

      
    
      return () => {
        
      }
    }, [])

    



  return (
    <div>
       <section class="news-block">
       <div class="blog-page-block">
           <div class="container">
               
               <div class="row justify-content-center">
                   <div class="col-12">

                   <div class="blog-latest-items row">

                   {
                    events.map((x,i)=>                      
                    i<=2?
                    <div class="col-lg-4 col-md-6">
                        <article class="post ">
                        <Link to={`/exhibition/${x.slug}`}>
                            <figure class="post-thumb">
                              
                                    <img src={'https://admin.jccaindia.com'+x.image} alt="Blog Image"/>
                           
                            </figure>
                            </Link>
                            <div class="post-details ice-details">
                                <div class="entry-meta-content">
                                    <div class="entry-cat">
                                        <Link to={`/exhibition/${x.slug}/`}>
                                            Date: {x.start.slice(0, 10).split('-').reverse().join('-')}</Link>
                                    </div>
                               
                                    <div class="entry-date ">
                                        <span>March 2020</span>
                                    </div>
                                 
                                </div>
                               <h2 class="entry-title"><Link to={`/exhibition/${x.slug}/`}>
                               {x.title}</Link>
                               </h2>
                                <div class="entry-content">
                                    <p>{x.description.slice(0,60)}...</p>
                                    <div class="btn-group-default date_btn">
                     
                        <Link  to={`/exhibition/${x.slug}`} class="btn btn-default btn-black hover-filled-slide-down">
                        Read More
                   {/*<img src="images/Vector.png" alt='no'/>*/}
                         
                         </Link>
                    </div>
                                </div>
                               
                            </div>
                        </article>
                    </div>
                   :null
                   
           
                    
                    )
                   }

                   </div>

                   
               

                

                    
                   </div>
               </div>
           </div>
       </div>
      
      </section>

  
    

    
    
    
    </div>
  )
}

export default Events