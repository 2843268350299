import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom'

function Footer() {
  return (
    <div>
    
    

    <div class="footer-widget-area ptb-100 bg-image bg-parallax1"
    >
   
    <div class="container ml-b-60 ml-t-5">
    <div class="row mx-md-n5">
    <div class="col-lg-4 px-md-5">
   
    <aside class="widget bt-location-widget">
   
    <h2 class="widget-title">JCCA Contact Details</h2>
   
    <div class="footer-logo">
                                   <img src="../assets/images/jccalogo.png" style={{width:'100px', height:'100px', objectFit:'contain', marginLeft:'-30px'}}/>
                                   <p>Address: GreenC, 872, Gautam Marg, Rani Sati Nagar, Jaipur-302019</p>
                                   
                                   <p>Email: info@jccaindia.com</p>
                                   <p style={{whiteSpace:'pre'}}>
                                   Mobile: +91-9079975079, +91-6350683793
                                   
                                   </p>
                                   </div>
    </aside>
   
    
    </div>
   
    
   
    <div class="col-lg-3 px-md-5">
    <aside class="widget widget-business-hours">
        <h2 class="widget-title">Useful Links</h2>
        <div class="widget-content">
            <ul class="list">

            <Link to="/about">
            <li>About us</li>
            </Link>

            
            <Link to="/testimonial">
            <li>Testimonials</li>
            </Link>

            <Link to="/books">
            <li>Books</li>
            </Link>

            <Link to="/contact">
            <li>Contact us</li>
            </Link>


           

            
                
            
               
          
              
            </ul>
        </div>
    </aside>
   </div>
   
   
   
   <div class="col-lg-5 px-md-5">
    <aside class="widget bt-instafeed-widget">
        <h2 class="widget-title">Let’s Get In Touch</h2>
        <div class="widget-content">
            <ul id="instafeed">
                <li class="feed-item">
                                      <a href="https://www.facebook.com/JCCAINDIA/"><span class="fa fa-facebook"></span></a>
                </li>
                   <li class="feed-item">
                               <a href="https://twitter.com/JCCAINDIA"><span class="fa fa-twitter"></span></a>
                </li>
                <li class="feed-item">
         
                              <a href="https://www.linkedin.com/company/jccaindia/"><span class="fa fa-linkedin"></span></a>
             
                </li>
             
                <li class="feed-item">
                    <a href="https://www.instagram.com/jcca_india/"><span class="fa fa-instagram"></span></a>
                </li>
   
                <li class="feed-item">
                <a href="https://www.youtube.com/@JCCAINDIA/"><span class="fa fa-youtube"></span></a>
            </li>
             
            </ul>
        </div>
    </aside>

    <div class="">
    
    <div class="row ">
    
    
    <div class="col-lg-12 mx-auto ">
        <div class="center_sec">
    <h1>STAY IN TOUCH</h1>
        <p>Join  our email list  and be first to  know about special  events and more! </p>
        </div>   </div>   </div>
     <form action="/action_page.php" class="form_sec">
     <div class="row ">
    
    
  
      <div class="col-lg-8 ">
      <input type="text" id="lname" name="Your Email" placeholder='Email'/>
    </div>
    <div class="col-lg-4 ">
    
    <button>SIGN  UP</button>
        </div>
    
    
     </div>
     
     </form>
      </div>



   </div>




   
   
   
    </div>
    
   
   
   
   
   
    <div class="copyright-text">
    <p>Copyright © 2023. All rights reserved <a href="https://digitalwhopper.com/">Digital Whopper.</a></p>
   </div>
   
   
    </div>
   
   
    
   
    
   
   
   
   
   
   
       
       
       </div>


    
    
    </div>
  )
}

export default Footer