import React from 'react'
import Header from './Header'
import Footer from './Footer'

function About() {


  return (
    <div>
    
  <Header/>

<div class="about-us-block ptb-100" style={{marginTop:'80px'}}>
    <div class="container">

    <div class='img-area'>

    <img src='../assets/images/bgpattern.png'   alt='n'></img>
    
    </div>

      <div class="row align-items-center center">
           
            <div class="col-lg-10">
                <div class="section-title style-two">
                <h1 className='text-center book-heading' >About Us</h1>
                   
               
                </div>
                <div class="about-content">
                    <p>Jaipur is the hub of traditional art and culture that dates back thousands of years. Jaipur Center of Culture and Arts (JCCA) is established on the principles and ideas of having a center of creativity in the fields of art and culture, that inspires and supports Indian as well as International Artistic activities, that can be enjoyed by the people of the pink city Jaipur, and also by all the people who visit this beautiful city, from all corners of India, as well as from around the world.</p>
<p>
The center’s director, Ms Monika Sharda, an avid painter and sculptor, and the recepient of many recognitions and awards for her artworks, is spearheading the artistict direction of the gallery. Her active research is aimed mainly at promoting new opportunities for current collecting. </p>

<p>
She is being ably supported by Jyotirmoy Bhattacharya, a national level curator, who is directing the present show of Celebrating Himmat Shah’s 90.
</p>
      <div class="row ">
      
                          <div class="col-lg-6">
                          <p>The center opened in 2023 and is located at the GreenC Art Gallery, Jaipur, the capital of the state of Rajasthan, which is famous for its Bandhani; Block printing; Stone carving and Sculpture; Tarkashi; Zari, Gota, Kinari and Zardozi; Silver Jewellery; Gems, Kundan, Meenakari and Jewellery; Miniature paintings; Blue Pottery; Ivory carving; Shellac work; Leather ware.
</p>
                          </div>
                            <div class="col-lg-6">
                          <p>It is one of the residences chosen by the greatest ancient and contemporary artists. The spirit of this place, deeply linked to the material, leaves an enduring mark in the choice of the artists represented.

</p>
                          </div>
                          
                             <div class="col-lg-6">
                                        <h2 class="title-main">Director’s Message</h2>
                        <img src="../assets/images/monika.jpg" alt='n' width="100%"/>
<p>Ms Monika Sharda, an avid painter and sculptor, and the recepient of many recognitions and awards for her artworks, is spearheading the artistict direction of the JCCA gallery. Her active research is aimed mainly at promoting new opportunities for current collecting.
</p>

                          </div>
                             <div class="col-lg-6">
                                        <h2 class="title-main">Curator’s Message
</h2>
                        <img src="../assets/images/bhatta.jpg" width="100%" alt='n'/>
<p>Jyotirmoy Bhattacharya is an Indian art curator and film maker based in Kolkata, India. He studies Art, History, Visual Studies, Archives and Visual Histories. He has curated shows with Akbar Padamsee, Jogen Chowdhury, S. G. Vasudev, Subhaprasanna and other notable artists. He has organized art camps of eminent artists in India and outside. He has curated numerous shows in Bangalore where he was based before he moved back to Kolkata.

</p>

                          </div>
                          
                          
                          
                             <div class="col-lg-6">
                                        <h2 class="title-main">Director's Note about the Gallery</h2>
        
<p>The idea of developing a creative space in Jaipur was born in the period of the pandemic when the entire human civilisation was threatened. It was a result of many factors converging into creating such a space.

</p>
<p>One of the major factors was not getting the taste of contemporary artworks that I enjoyed during my decade long stay abroad. We realised that Jaipur stands on the top in terms of art and creativity in India but something is still missing, probably a large and open creative space for the display of modern and contemporary works. Then came the pandemic period, which rather had made the idea more conducive to develop an open and creative platform to express yourself out creatively through art. Of course, loosing job during this period was another factor, which further pushed me to work upon the idea!


</p>
<p>The founding stone of JCCA was laid by my father-in-law, Late Shri Prabhu Dayal Sharda, who also performed Hawan (opening of a building as per Hindu tradition) of JCCA after 20 months of its construction. His inspiring life has been a symbol of fusion of the tradition with contemporary, which was, indeed, another major reason in coming up for the creative space. In fact, he left into space after blessing this space of culture and art in Jaipur.
<ul>
<li>
(a). Multi-faceted facade, erected with red sandstone, colouring the space with the world known Jaipur Pink.


</li>
<li>
(b). Four floors of pillar-fewer wide halls allowing one of the largest private display areas in India with world’s best wall washers and track lights



</li>
<li>
(c). Dynamic variation of air-conditioned space, at each floor, providing flexibility as per the choice of display for curators and artists



</li>
<li>
(d). Environment friendly by generating its own electricity through Solar Panels on the terrace and having rain harvesting




</li>
<li>
(e).Well equipped with facilities including an Art Cafe, Terrace Garden, Meditation room, Projector room, etc.



</li>
<li>
(f). Multi-faceted facade, erected with red sandstone, colouring the space with the world known Jaipur Pink.


</li>
<li>
(g).State of the art security



</li>

</ul>


</p>

                          </div>
                             <div class="col-lg-6">
                                        <h2 class="title-main">Vision & Mission

</h2>
            
<p>JCCA’s vision is to encourage the enjoyment and engagement of the Art and Culture of our great nation, India. We believe in the power of art to bridge gaps and spark our collective imaginations. 


</p>
<p>JCCA’s mission is to present, collect and help interpret historic and contemporary art. We wish to contribute in understanding the critical role of art in the changing world around us. Moreover, we aspire to promote critical thinking and contribute to the career growth of different artists. We are on the lookout for masterpieces of tomorrow and thus create a space where different ideas and practices can be safely explored.



</p>

                          </div>
                
                </div>
            </div>
        </div>
    </div>
</div>
</div>

<Footer/>

    
    
    
    </div>
  )
}

export default About