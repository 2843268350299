import React, { useEffect, useState } from "react";
import "../Component/advance.css"
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import { Box } from "@mui/material";
import { useParams } from "react-router";
import axios from "axios";
import Carousel from "react-bootstrap/Carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const NewsDetail = () => {
  
    const [newsdeatil, setNewsdeatil] = useState({});
    const [allnews, setAllNews] = useState([]);
    const [reading, setReading] = useState(false);
    const { slug } = useParams();
    useEffect(() => {
        axios
          .get("https://admin.jccaindia.com/api/news")
          .then((res) => {
            if (res.status === 200) {
              setAllNews(res.data.data);
            }
          })
          .catch((err) => console.log(err.message));
    
        axios
          .get(`https://admin.jccaindia.com/api/news-details/${slug}`)
          .then((res) => {
            if (res.status === 200) {
              setNewsdeatil(res.data.data);
            }
          })
          .catch((err) => console.log(err.message));

        return () => {};
      }, [slug]);

      const contentShow=()=>{
        setReading(!reading)
      }
  return (
    <>
    <Header/>
    <h1 className="mx-auto text-center pb-3" style={{marginTop:'120px'}}>{newsdeatil?.news_heading} <br/> {newsdeatil?.date?.slice(0,10).split('-').reverse().join('-')} | {newsdeatil?.news_writer}</h1>
    <div className='container bookdetail-container py-4' >
            
            <div className="col-md-8 col-md-8 col-sm-12 text-center" >
                <img src={ "https://admin.jccaindia.com" + newsdeatil.image} alt="news" />
                <div className="container py-4 text-start">               
                {!reading && <p style={{fontSize:'18px',color:'#777'}}>{newsdeatil?.news_detail?.slice(0,newsdeatil?.news_detail?.length/3)}</p>}
                {reading && <p style={{fontSize:'18px',color:'#777'}}>{newsdeatil?.news_detail}</p>}
                {!reading && <button className='border-0' onClick={contentShow} >Read More &rarr;</button>}
                { reading && <button className='border-0' onClick={contentShow} >Read Less &rarr;</button>}
                </div>
            </div>
            
            <div className="col-md-4 col-sm-12">              
                
                <h2 class="Events_parts mt-4 " style={{ borderBottom: '2px solid gray' }}>Recent News</h2>

                  <Box >
                 
                  <Carousel data-bs-theme="dark">
                {allnews?.map((x) => (
                 
                  <Carousel.Item>
                     <Link to={`/news-details/${x.slug}`}>
                    <img
                      className="d-block w-100"
                      src={"https://admin.jccaindia.com" + x.image}
                      alt="First slide"
                      
                    />
                    <Carousel.Caption>
                      {/* <h5 style={{fontSize:'20px',color:'white'}}>{x.date?.slice(0,10)}</h5>
                        <p style={{fontSize:'18px'}}>{x.news_heading}</p> */}
                    </Carousel.Caption>
                    </Link>
                  </Carousel.Item>
                 
                ))}
              </Carousel>
              <h2 class="Events_parts mt-4 pt-4" style={{ borderBottom: '1px solid gray' }}>Archives</h2>
              <ul style={{fontSize:'20px'}}>
              <li >{newsdeatil?.date?.slice(0,4)}</li>
              </ul>
                  </Box>
                
            </div>
            </div>
            
      
      <Footer/>
    </>

  )
}

export default NewsDetail